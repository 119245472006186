<template>
    <div class="mehr btm-menu">
        <Breadcrumb :background="true" :backButton="false" content="Weitere Inhalte" />
        <ul class="mehr-menu">
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <img src="/assets/icons/menu-icon-1.png"></img>
                </div>
                <router-link class="mehr-menu-link__text" to="/category/Beratung & Hilfe">Beratung & Hilfe</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <img src="/assets/icons/menu-icon-2.png"></img>
                </div>
                <router-link class="mehr-menu-link__text" to="/category/Freizeit">Freizeit</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <img src="/assets/icons/menu-icon-3.png"></img>
                </div>
                <router-link class="mehr-menu-link__text" to="/category/Ausbildung & Weiterbildung">Ausbildung & Weiterbildung</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">bookmark_border</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Merkliste'}">Merklisten</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link seprator">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">place</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'BildungsFinder'}">Kita- und Schulfinder</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">description</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Nutzungsbedingungen'}">Nutzungsbedingungen</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">shield</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Datenschutz'}">Datenschutzerklärung</router-link>
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">accessibility_new</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Barrierefreiheitserklaerung'}">Barrierefreiheitserklärung</router-link>
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li class="mehr-menu-link seprator">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">person</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Impressum'}">Impressum</router-link>
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li v-if="!userLoggedIn && !$hideUserAccountRelatedFunctions" class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined">person</span>
                </div>
                <router-link class="mehr-menu-link__text" :to="{ name: 'Login'}">Login</router-link>                
                <span class="material-icons ml-auto">navigate_next</span>
            </li>
            <li v-if="userLoggedIn && !$hideUserAccountRelatedFunctions" class="mehr-menu-link">
              <div class="mehr-menu-link__icon">
                  <span class="material-icons-outlined">person</span>
              </div>
              <router-link class="mehr-menu-link__text" :to="{ name: 'Profile'}">Mein Profil</router-link>   
              <span class="material-icons ml-auto">navigate_next</span>
          </li>
          <li v-if="userLoggedIn && !$hideUserAccountRelatedFunctions" class="mehr-menu-link">
            <div class="mehr-menu-link__icon">
                <span class="material-icons-outlined">lock</span>
            </div>
            <a style="cursor: pointer" href="/" class= "mehr-menu-link__text" @click.prevent="logOut">Logout</a>               
            <span class="material-icons ml-auto">navigate_next</span>
        </li>

        <li style="text-align: center;" class="mt10">
          <a href="https://cms.wolfsburgerlupe.de/#/" target="_blank" class="btn btn-active mt10 mb20">
            <span>Login für Anbietende</span>
        </a>
    </li>

</ul>
</div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';


  export default {
    name: 'Mehr',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
  },
  computed: {
      userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
      }
      return false;
  },
  currentRoute(){
    return this.$route.name;
}
},
methods: {
  showLoader,
  hideLoader,
  logOut(){
    this.loader = this.showLoader(this.loader);
    this.$store.dispatch('logout');

    this.$notify({
      duration: 3500,
      title: 'Erfolgreich abgemeldet!',
      text: 'Sie haben sich von der Wolfsburger Lupe abgemeldet.'
  });

    this.loader = this.hideLoader(this.loader);
    this.$router.push({name:'Home'});
},
},
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .to-upper{
        text-transform:uppercase;
    }

    .software-by-design-icon{
        width:24px;
        height:24px;
    }

    .mehr {
        z-index: 0;
        background-color: $white-color;
        height: 100vh;
        display: flex;
        flex-direction: column;

        &-menu {
            display: flex;
            flex-direction: column;

            &-link {
                display: flex;
                flex-direction: row;
                height: 50px;
                align-items: center;
                padding: 0 15px;

                &.seprator {
                    border-bottom: 1px solid rgba($color: $dark-color, $alpha: 0.2);
                }

                &__icon{
                    width: 40px;
                    display: inline-flex;
                    align-items: center;
                }

                &__text {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    color: $dark-color;
                }
            }
        }
    }

    .mehr-menu-link__icon img {
        margin-left: 3px;
    }
</style>
